body {
  font-family: 'Source Sans Pro', sans-serif;
}

button {
  font-family: 'Roboto', sans-serif;
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%)
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s .6s opacity
}

@media (prefers-reduced-motion:reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 15px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-indicators li {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center
}


.carousel-control {
  background-color: #f5593d;
  border-radius: 50%;
  opacity: 1;
  text-shadow: none;
}

.carousel-control:hover,
.carousel-control:focus {
  opacity: 1;
  background-color: #f33816;
}

.carousel-control.left {
  height: 30px;
  top: 50%;
  width: 30px;
  left: 25px;
  opacity: 0;
}

.carousel-control.right {
  height: 30px;
  right: 25px;
  top: 50%;
  width: 30px;
  opacity: 0;
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .fa,
.carousel-control .fa {
  display: inline-block;
  z-index: 5;
}

.carousel-control .fa {
  font-size: 26px;
  margin: 2px;
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
}

.page-carousel {
  border-radius: 12px !important;
  border: none !important;
  width: 100%;
}

.carousel-inner>.carousel-item>img,
.carousel-inner>.carousel-item>a>img {
  border-radius: 0;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
}

.carousel-inner .carousel-item img {
  width: 100%;
}

.carousel-indicators>li {
  background-color: rgba(204, 204, 204, 0.49);
  border: 0 none;
}

.carousel-indicators .active {
  background-color: #f5593d;
}

.page-carousel:hover .carousel-control.right,
.page-carousel:hover .carousel-control.left {
  opacity: 1;
  z-index: 1;
}

.card .carousel .carousel-indicators {
  bottom: 0;
}

.card .carousel a {
  position: absolute;
  color: #FFFFFF !important;
}

.card .carousel a:hover {
  color: #FFFFFF !important;
}

.card .carousel a .fa-angle-left:before {
  padding-right: 3px;
}

.card .carousel a .fa-angle-right:before {
  padding-left: 3px;
}

.footer {
  position: fixed;
  line-height: 40px;
  color: #efefef;
  font-size: 0.9em;
  bottom: 10px;
  width: 100%;
  background: transparent;
}

.footer a {
  color: #efefef;
}